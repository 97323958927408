/**
 * Returns if WaFd Bank is in a state.
 * @param {string} state Two character state code
 * @return {boolean}
 */
const isWaFdBankInState = function (state) {
  return ["AZ", "CA", "ID", "NV", "NM", "OR", "TX", "UT", "WA"].indexOf(state) >= 0;
};

export default isWaFdBankInState;
