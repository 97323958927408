import React from "react";
import { Link } from "gatsby";

// Components
import Icon from "../components/custom-widgets/icon";
import getViewOnGoogleMapsUrl from "../helpers/getViewOnGoogleMapsUrl";
import getStateCodeFromState from "../helpers/getStateCodeFromState";
import isWaFdBankInState from "../helpers/isWaFdBankInState";
import BankerHeadshot from "../components/banker-headshot";

const CommunityBankingOfficerSummary = (props) => {
  const stateCode = isWaFdBankInState(props.communityBankingOfficer.Address.State)
    ? props.communityBankingOfficer.Address.State
    : getStateCodeFromState(props.communityBankingOfficer.Address.State);

  const state = `${props.communityBankingOfficer.RegionAndDivision[0].State}`;

  const bankerName = props.communityBankingOfficer.Name.replace(/ +/g, "-").toLowerCase();

  return (
    <div>
      <h3 id={`${bankerName}-${state}-name`}>{props.communityBankingOfficer.Name}</h3>
      <div className="row">
        <div className="col-12 col-lg-3">
          <BankerHeadshot
            email={props.communityBankingOfficer.EmailAddress}
            style={{ marginTop: "0.25rem" }}
            aria-label={`WaFd Bank Community Banking Officer ${props.communityBankingOfficer.Name}`}
          />
        </div>
        <div className={`col-12 col-lg-9`}>
          <div className="mb-1">{props.communityBankingOfficer.RegionAndDivision.Division}</div>
          <ul className="list-unstyled">
            <li>
              <a
                href={getViewOnGoogleMapsUrl(props.communityBankingOfficer)}
                target="_blank"
                rel="noopener noreferrer"
                className="align-items-baseline d-flex text-decoration-none"
                id={`${bankerName}-${state}-address`}
              >
                <Icon lib="fal" name="map-marker-alt" class="mr-2 fa-fw" />
                {props.communityBankingOfficer.Address?.StreetAddress}
                <br />
                {props.communityBankingOfficer.Address?.City}, {stateCode}{" "}
                {props.communityBankingOfficer.Address?.ZipCode}
              </a>
            </li>
            <li>
              <a
                id={`${bankerName}-${state}-phone`}
                href={`tel:${props.communityBankingOfficer.PhoneNumber}`}
                className="align-items-baseline d-flex text-decoration-none"
              >
                <Icon lib="fal" name="phone-alt" class="mr-2 fa-fw" />
                {props.communityBankingOfficer.PhoneNumber}
              </a>
            </li>
            <li>
              <a
                id={`${bankerName}-${state}-email`}
                href={`mailto:${props.communityBankingOfficer.EmailAddress}`}
                className="align-items-baseline d-flex text-decoration-none"
              >
                <Icon lib="fal" name="envelope-open-text" class="mr-2 fa-fw" />
                {props.communityBankingOfficer.EmailAddress}
              </a>
            </li>
          </ul>
        </div>
      </div>
      {props.communityBankingOfficer.MSBookingsBranchName ? (
        <div className="row">
          <div className="col-sm-6">
            <Link
              to={"/make-an-appointment?bankerName=" + props.communityBankingOfficer.MSBookingsBranchName}
              className="btn btn-primary"
            >
              Make an Appointment
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};

CommunityBankingOfficerSummary.defaultProps = {
  communityBankingOfficer: {
    id: null,
    EmailAddress: null,
    Name: null,
    PhoneNumber: null,
    RegionAndDivision: [
      {
        Division: null
      }
    ],
    ProfilePhoto: {
      childImageSharp: null
    },
    Address: {
      BranchGeoLocation: {
        Lat: null,
        Lng: null
      },
      City: null,
      Country: null,
      State: null,
      StreetAddress: null,
      ZipCode: null
    },
    MSBookingsBranchName: null,
    Distance: null
  }
};

export default CommunityBankingOfficerSummary;
