import getStateCodeFromState from "../helpers/getStateCodeFromState";

/**
 * @param {Object} branchLocation
 * @param {Object} branchLocation.Address
 * @param {string} branchLocation.Address.StreetAddress
 * @param {string} branchLocation.Address.City
 * @param {string} branchLocation.Address.State
 * @param {string} branchLocation.Address.ZipCode
 * @return {string}
 */
function getViewOnGoogleMapsUrl(branchLocation) {
  const stateCode = getStateCodeFromState(branchLocation.Address.State);

  return (
    "https://maps.google.com/maps?daddr=" +
    branchLocation.Address.StreetAddress +
    "%2c" +
    branchLocation.Address.City +
    "%2c" +
    stateCode +
    "%2c" +
    branchLocation.Address.ZipCode
  );
}

export default getViewOnGoogleMapsUrl;
